$(document).ready(function(){

  $('.m-menu').click(function(){
    $('html, body').toggleClass('noscroll');
    $('body').toggleClass('m-menu-open');
  });

  $('nav a').click(function(){
    if($('body').hasClass('m-menu-open')){
      $('body').removeClass('m-menu-open');
      $('html, body').removeClass('noscroll');
    }
  })
  $('#gallery').slick({
    infinite: true,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true
  });
  $("#trailer").fitVids();

});